import React, { useState } from 'react'
import leftMenu from '../../Images/leftMenu.png'
import closeMenu from '../../Images/closeMenu.png'
import telephone from '../../Images/telephone.png'
import book from '../../Images/book.png'
import Banner2Logo from '../../Images/LogoHeader.png'
import Logo from '../../Images/Logo.png'
import lOGO2 from '../../Images/lOGO2.png'
// 
import './Header.scss';
import Arrow from '../../Images/downArrow.png'
import { useLocation, useNavigate } from 'react-router-dom'

export default function Header({CitiePhone, CitiesData, showMenu, setShowPopUp, setShowMenu }) {
    const [header, setHeader] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    return (
        <div className="Header2">
            {/* HeaderBottom */}
            <div className="HeaderBottom">
                <div className='Header2'>
                    <div className="Header2Location">
                        <img src={require(`../../Images/place.png`)} alt="" />
                        <h1>{CitiesData.City ? CitiesData.City.toUpperCase() : "Local"}</h1>
                    </div>

                </div>
                <div className="Header1Main">
                    <div className="Header2Circle">
                        <div className='circle'>
                            <span className='hdhs1'></span>
                            <span className='hdhs2'></span>
                        </div>
                        <h3>Open Now / Same Day Service</h3>
                    </div>
                    <div className='Header1'>
                        <a onClick={() => navigate(`/book${location.search}`)}>SCHEDULE <span>&nbsp;ONLINE</span><img src={book} alt="Garage | Garage Repair | Garage Services | Garage Inspection In Master," /></a>
                    </div>
                    <div className='Header1'>
                        <a target='_blank' href={`tel:${CitiePhone}`}>CALL <span>&nbsp;{CitiePhone}</span><img src={telephone} alt="Garage | Garage Repair | Garage Services | Garage Inspection In Master," /></a>
                    </div>
                </div>
            </div>
            {/* HeaderTopComputer */}
            <div className="HeaderTopComputer">
                <div className="Header2" >
                    <div className="Header2Logo">
                        <img src={lOGO2} alt="Garage | Garage Repair | Garage Services | Garage Inspection In Master," />
                        {/* <p>Adani's GARAGE DOOR</p> */}
                    </div>
                    <div className="Header2LogoName">
                        <h1>Adani's GARAGE DOOR</h1>
                        <h2>Your Local Garage Company</h2>
                    </div>
                </div>

                <div className="Header1">
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            <a onClick={() => {
                                window.scrollTo(0, 0)
                                navigate(`/${location.search}`)
                            }
                            }>HOME PAGE</a>
                        </div>
                    </div>
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            {location.pathname == "/book" ?
                                <a href={`/${location.search}#ourservices`}>OUR SERVICES</a>
                                :
                                <a onClick={() => window.location.hash = 'ourservices'}>OUR SERVICES</a>
                            }
                        </div>
                    </div>
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            {location.pathname == "/book" ?
                                <a href={`/${location.search}#reviews`}>REVIEWS &gt;</a>
                                :
                                <a onClick={() => window.location.hash = 'reviews'}>REVIEWS &gt;</a>
                            }
                        </div>
                    </div>
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            {location.pathname == "/book" ?
                                <a href={`/${location.search}#contact`}>CONTACT US</a>
                                :
                                <a onClick={() => window.location.hash = 'contact'}>CONTACT US</a>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* HeaderTopPhone */}
            <div className="HeaderTopPhone">
                <div className="Header2" >
                    <div className="Header2Logo">
                        <img src={lOGO2} alt="Garage | Garage Repair | Garage Services | Garage Inspection In Master," />
                    </div>
                    <div className="Header2LogoName">
                        <a href="/">Adani's GARAGE DOOR</a>
                        <p>Your Local Garage Company</p>
                    </div>
                </div>
                <div className='Header3'>
                    <div className="Header2Circle" onClick={() => { setShowPopUp(true) }}>
                        <div className='circle'>
                            <span className='hdhs1'></span>
                            <span className='hdhs2'></span>
                        </div>
                        <h3>Open Now / Same Day Service</h3>
                    </div>
                    {showMenu ?
                        <img
                            src={closeMenu}
                            className='menuCloseBtn'
                            onClick={() => setShowMenu(false)}
                        />
                        :
                        <img
                            src={leftMenu}
                            onClick={() => setShowMenu(true)}
                        />
                    }

                </div>
            </div>

        </div>
    )
}
