import './App.scss';
import Menu from '../Tools/Menu/Menu';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Offline, Online } from "react-detect-offline";
import Main from './Main';

// blogs images
import unlock from '../Images/unlock.png'
import reload from '../Images/reload.png'
import GarageImg1 from '../Images/GarageImg1.jpg'
import GarageImg2 from '../Images/GarageImg2.jpg'
import GarageImg3 from '../Images/GarageImg33.jpg'
import GarageImg4 from '../Images/GarageImg4.jpg'
import GarageImg6 from '../Images/GarageImg6.jpg'
import GarageImg7 from '../Images/GarageImg7.jpg'
import GarageImg8 from '../Images/GarageImg8.jpg'
import GarageImg9 from '../Images/GarageImg9.jpg'
import PopUp from '../Tools/PopUp/PopUp.jsx';


function App() {
  const [showMenu, setShowMenu] = useState(false)
  const [showVerify, setShowVerify] = useState(true)
  const [status, setStatus] = useState(true);
  const navigate = useNavigate()
  const location = useLocation()
  const [BookAnAppointment, setBookAnAppointment] = useState(true);
  const url = new URL(window.location.href);
  const [CitiesData, setCitiesData] = useState({});
  const [CitiePhone, setCitiePhone] = useState(url.searchParams.get('cp') ? url.searchParams.get('cp') : "415-740-3681 ");
  const [showPopUp, setShowPopUp] = useState(false)


  // locationsMain
  const locationsMain = [
    {
      "Location": "Orange County",
      "Phone": "714-942-4531",
      "Address": [
        {
          "Location1": "Orange County",
          "Address1": "7667 Garden Grove Blvd Garden Grove, CA 92841",
        },
      ],
      "Map": "https://www.google.com/maps/d/u/0/embed?mid=1igOR7boZkzmdyNwUcncKxxA65IggcHE&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "Riverside",
      "Phone": "951-904-6511",
      "Address": [
        {
          "Location1": "Riverside",
          "Address1": "3576 Arlington Ave Riverside, CA 92506",
        },
      ],
      "Map": "https://www.google.com/maps/d/u/0/embed?mid=1igOR7boZkzmdyNwUcncKxxA65IggcHE&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "Los Angeles",
      "Phone": "213-523-2986",
      "Address": [
        {
          "Location1": "Los Angeles",
          "Address1": "611 S Catalina St Los Angeles, CA 90005",
        },
      ],
      "Map": "https://www.google.com/maps/d/u/0/embed?mid=1igOR7boZkzmdyNwUcncKxxA65IggcHE&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "Valley",
      "Phone": "747-236-6316",
      "Address": [
        {
          "Location1": "Valley",
          "Address1": "16250 Ventura Blvd Encino, CA 91436",
        },
      ],
      "Map": "https://www.google.com/maps/d/u/0/embed?mid=1igOR7boZkzmdyNwUcncKxxA65IggcHE&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "Oxnard",
      "Phone": "805-691-0512",
      "Address": [
        {
          "Location1": "Oxnard",
          "Address1": "520 W 5th St Oxnard, CA 93030",
        },
      ],
      "Map": "https://www.google.com/maps/d/u/0/embed?mid=1igOR7boZkzmdyNwUcncKxxA65IggcHE&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "Santa Monica",
      "Phone": "424-383-9869",
      "Address": [
        {
          "Location1": "Santa Monica",
          "Address1": "8817 Beverly Blvd, Los Angeles, CA 90048",
        },
      ],
      "Map": "https://www.google.com/maps/d/u/0/embed?mid=1igOR7boZkzmdyNwUcncKxxA65IggcHE&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "Santa Clarita",
      "Phone": "661-990-9186",
      "Address": [
        {
          "Location1": "Santa Clarita",
          "Address1": "23300 Cinema Dr, Valencia, CA 91355",
        },
      ],
      "Map": "https://www.google.com/maps/d/u/0/embed?mid=1igOR7boZkzmdyNwUcncKxxA65IggcHE&ehbc=2E312F&noprof=1",
    },
  ]


  const navigateTo = (page) => {
    navigate('/' + page)
    setShowMenu(false)

  }
  useEffect(() => {
    (async () => {
      // google //
      if (url.searchParams.get('spg') == "g") {
        const CitiesZipGoogle2 = (await import('./CitiesZipGoogle2.js')).default;
        if (CitiesZipGoogle2.find(a => a.ID == url.searchParams.get('cd'))) {
          const res = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${CitiesZipGoogle2.find(a => a.ID == url.searchParams.get('cd')).ZC}&key=AIzaSyC_XBMupYyuYy-f1TGdo32rsoHBhSMWPK0&language=en`, { method: 'GET', })
          const data = await res.json()
          // console.log(data.results[0])
          setCitiesData({
            "ID": CitiesData.ID,
            "City":
              data.results[0].address_components.find(a => a.types.find(a => a == 'locality')) ?
                data.results[0].address_components.find(a => a.types.find(a => a == 'locality')).long_name :
                data.results[0].formatted_address.split(',')[0] + ", " + data.results[0].formatted_address.split(',')[1].substring(0, 3),
            "Full":
              data.results[0].address_components.find(a => a.types.find(a => a == 'locality')) ?
                data.results[0].address_components.find(a => a.types.find(a => a == 'locality')).long_name + ', ' + data.results[0].address_components.find(a => a.types.find(a => a == 'administrative_area_level_1')).long_name :
                data.results[0].formatted_address.split(',')[0] + ", " + data.results[0].formatted_address.split(',')[1].substring(0, 3),

          })
        } else {
          const CitiesDataGoogle = (await import('./CitiesDataGoogle.js')).default;
          if (CitiesDataGoogle.find(a => a.ID == url.searchParams.get('cd'))) {
            setCitiesData(CitiesDataGoogle.find(a => a.ID == url.searchParams.get('cd')))
          } else {
            setCitiesData({})
          }
        }
      }

      // Microsoft //
      if (url.searchParams.get('spg') == "m") {
        const CitiesZipMS2 = (await import('./CitiesZipMS2.js')).default;
        if (CitiesZipMS2.find(a => a.ID == url.searchParams.get('cd'))) {
          const res = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${CitiesZipMS2.find(a => a.ID == url.searchParams.get('cd')).ZC}&key=AIzaSyC_XBMupYyuYy-f1TGdo32rsoHBhSMWPK0&language=en`, { method: 'GET', })
          const data = await res.json()
          setCitiesData({
            "ID": CitiesZipMS2.find(a => a.ID == url.searchParams.get('cd')).ID,
            "City":
              data.results[0].address_components.find(a => a.types.find(a => a == 'locality')) ?
                data.results[0].address_components.find(a => a.types.find(a => a == 'locality')).long_name :
                data.results[0].formatted_address.split(',')[0] + ", " + data.results[0].formatted_address.split(',')[1].substring(0, 3),
            "Full":
              data.results[0].address_components.find(a => a.types.find(a => a == 'locality')) ?
                data.results[0].address_components.find(a => a.types.find(a => a == 'locality')).long_name + ', ' + data.results[0].address_components.find(a => a.types.find(a => a == 'administrative_area_level_1')).long_name :
                data.results[0].formatted_address.split(',')[0] + ", " + data.results[0].formatted_address.split(',')[1].substring(0, 3),

          })
        } else {
          const CitiesDataMS = (await import('./CitiesDataMS.js')).default;
          if (CitiesDataMS.find(a => a.ID == url.searchParams.get('cd'))) {
            setCitiesData(CitiesDataMS.find(a => a.ID == url.searchParams.get('cd')))
          } else {
            setCitiesData({})
          }
        }
      }
    })();
    if (showVerify == true) {
      document.body.style.overflow = "hidden"
      document.body.style.overflow = "scroll"
    } else {
      document.body.style.overflow = "scroll"
    }

    window.addEventListener('online', () => setStatus(true))
    window.addEventListener('offline', () => setStatus(false))

  }, [showVerify, status]);

  return (
    <div className="App" style={false ? { "document.html.style.overflow": "hidden" } : null}>
      {/* <Online>Only shown when you're online</Online> */}
      {/* {!status ? */}
      <Offline>
        <div className="OfflineApp">
          <h1><img src={unlock} alt="" /><span>Internet Connection Is Not Stable.</span>Your network seems to be disconnected. Please check your internet connection.</h1>
          <img onClick={() => window.location.reload()} className='OfflineAppClose' src={reload} alt="" />
        </div>
      </Offline>
      {/* : null} */}

      {/* PopUp */}
      {/* {showPopUp ?
        <PopUp CitiePhone={CitiePhone} CitiesData={CitiesData} setShowPopUp={setShowPopUp} />
        : null} */}
      {/* Menu */}
      {showMenu ? <Menu  navigateTo={navigateTo} setShowMenu={setShowMenu} /> : null}
      {/* Main */}
      <Main showMenu={showMenu} setShowPopUp={setShowPopUp} setShowMenu={setShowMenu} CitiesData={CitiesData} CitiePhone={CitiePhone} locationsMain={locationsMain}  navigateTo={navigateTo} />

      {/* oNLINE */}
      {location.pathname == "/book" ? null : <a className='callButtonMainApp' href={`tel:${CitiePhone}`}> Click To Call! <img src={require(`../Images/telephone.png`)} />{CitiePhone}</a>}

    </div>
  );
}

export default App;
