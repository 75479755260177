import React from 'react'
import { Route, Routes } from 'react-router-dom'
import NotFound from '../Tools/NotFound/NotFound'
import Thanks from '../Tools/Thanks/Thanks'
import BookAnAppointmentLocal from '../HomePageGoogle/BookAnAppointment/BookAnAppointment'
import HomePageGoogle from '../HomePageGoogle/HomePageGoogle'
import PrivacyPolicy from '../Tools/PrivacyPolicy/PrivacyPolicy'
import TermsAndConditions from '../Tools/TermsAndConditions/TermsAndConditions'

export default function Main({ showMenu, setShowPopUp, CitiesData, setShowMenu, CitiePhone, locationsMain, navigateTo, Blogs, FaqList, WebSitePages }) {

    return (
        // <div>
        <Routes>
            {/* Pages */}
            <Route path="/" element={<HomePageGoogle  showMenu={showMenu} setShowPopUp={setShowPopUp} setShowMenu={setShowMenu} CitiesData={CitiesData} CitiePhone={CitiePhone} locationsMain={locationsMain} navigateTo={navigateTo} Blogs={Blogs} FaqList={FaqList} />} />
            <Route path="/book" element={<BookAnAppointmentLocal setShowPopUp={setShowPopUp} setShowMenu={setShowMenu} CitiesData={CitiesData} CitiePhone={CitiePhone} locationsMain={locationsMain} navigateTo={navigateTo} Blogs={Blogs} FaqList={FaqList} />} />
            {/* Privacy Policy */}
            <Route path="/thanks" element={<Thanks navigateTo={navigateTo} />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            {/* 404 */}
            <Route path="*" element={<NotFound />} />
        </Routes>
        // </div>
    )
}
