import React, { useState } from 'react'
import './PrivacyPolicy.scss'
import { useLocation } from 'react-router-dom';

export default function PrivacyPolicy() {
    const location = useLocation();
    return (
        <div className="PrivacyPolicy">
            <p>
                <h3>PRIVACY POLICY</h3>
                <br />
                Last updated: Effective as of November 02, 2024 / 11/02/2024</p>
            <p>This Privacy Policy describes our policies and procedures on the collection, use, and disclosure of your information when you use our service. It also explains your privacy rights and how the law protects you.</p>
            <p>We use your personal data to provide and improve our service. By using the service, you agree to the collection and use of information in accordance with this Privacy Policy.</p>
            <h3>Interpretation and Definitions</h3>
            <h3>Interpretation</h3>
            <p>
                Words with initial capital letters have meanings defined under the following conditions. The definitions apply whether they appear in singular or plural form.</p>
            <p>
                <h3>Definitions</h3></p>
            <p>For the purposes of this Privacy Policy:</p><ul>
                <li>
                    <strong>Account</strong> means a unique account created for you to access our service or parts of our service.</li>
                <li>
                    <strong>Affiliate</strong> means an entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50% or more of shares or other securities entitled to vote for directors or managing authority.</li>
                <li>
                    <strong>Company</strong> (referred to as "the Company," "We," "Us," or "Our") refers to Adani's Garage Door. 14850 Weddington St, Sherman Oaks, CA 91411</li>
                <li>
                    <strong>Cookies</strong> are small files placed on your device by a website, containing details of your browsing activity.</li>
                <li>
                    <strong>Country</strong> refers to the United States.</li>
                <li>
                    <strong>Device</strong> means any device that can access the service, such as a computer, cellphone, or digital tablet.</li>
                <li>
                    <strong>Personal Data</strong> refers to any information that identifies an individual.</li>
                <li>
                    <strong>Service</strong> refers to the website and services provided by Adani's Garage Door.</li>
                <li>
                    <strong>Service Provider</strong> refers to any individual or company that processes data on behalf of Adani's Garage Door.</li>
                <li>
                    <strong>Usage Data</strong> refers to data collected automatically when using the service or from the service infrastructure (e.g., page visit duration).</li>
                <li>
                    <strong>Website</strong> refers to Adani's Garage Door, accessible from <a href="https://www.adanisbuildersgaragedoor.com">https://www.adanisbuildersgaragedoor.com</a></li>
                <li>
                    <strong>You</strong> means the individual accessing or using the service, or the company or legal entity on behalf of which such individual is accessing or using the service.</li></ul>
            <h3>Collecting and Using Your Personal Data</h3>
            <h3>Types of Data Collected</h3>
            <h3>Personal Data</h3>
            <p>
                While using our service, we may ask you to provide certain personally identifiable information, including but not limited to:</p>
            <ul>
                <li>Email address</li>
                <li>First and last name</li>
                <li>Phone number</li>
                <li>Address, State, ZIP/Postal code, City</li>
                <li>Usage data</li>
            </ul>

            <h3>Usage Data</h3>
            <p>
                Usage data is collected automatically when using the service. This data may include information such as your device's IP address, browser type, browser version, pages visited, time and date of your visit, time spent on those pages, and other diagnostic data.</p>
            <p>When accessing the service via a mobile device, we may collect information such as the type of mobile device you use, unique device ID, IP address, mobile operating system, and browser type.</p>

            <h3>Tracking Technologies and Cookies</h3>
            <p>
                We use cookies and similar tracking technologies to track activity and store information. Examples include:</p><ul>
                <li>
                    <h3>Cookies or Browser Cookies</h3>: You can instruct your browser to refuse all cookies. However, some parts of our service may be inaccessible without cookies.</li>
                <li>
                    <h3>Web Beacons</h3>: Embedded in some emails or sections of our service to monitor page traffic.</li></ul>

            <h3>Use of Your Personal Data</h3>

            <h3>The Company may use personal data for the following purposes:</h3><ul>
                <li>
                    <strong>To provide and maintain our service</strong>.</li>
                <li>
                    <strong>To manage your account</strong>.</li>
                <li>
                    <strong>To fulfill contracts</strong> for purchases or services.</li>
                <li>
                    <strong>To contact you</strong> via email, phone, SMS, or other methods regarding service updates.</li>
                <li>
                    <strong>To provide you with news and special offers</strong>, unless you opt-out.</li>
                <li>
                    <strong>To manage your requests</strong>.</li>
                <li>
                    <strong>For business transfers</strong> as part of mergers, acquisitions, or asset sales.</li>
                <li>
                    <strong>For other purposes</strong>, including data analysis, usage trends, and service improvement.</li></ul>
            <h3>Sharing Your Personal Information</h3>
            <p>may occur with service providers, affiliates, business partners, or with your consent.</p>

            <h3>Retention of Your Personal Data</h3>
            <p>We will retain your personal data only as long as necessary for the purposes outlined in this policy and to comply with legal obligations.</p>

            <h3>Transfer of Your Personal Data</h3>
            <p>Your information may be processed outside your jurisdiction, and by using the service, you consent to such transfers, subject to necessary data protection measures.</p>

            <h3>Delete Your Personal Data</h3>
            <p>You have the right to request deletion of your data. You can contact us for assistance in accessing, amending, or deleting personal data.</p>

            <h3>Disclosure of Your Personal Data</h3>
            <h3>Business transactions</h3>
            <p>If the Company is involved in a merger, acquisition, or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>

            <h3>Law enforcement</h3>
            <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>

            <h3>Other legal requirements</h3>
            <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
            <ul>
                <li>Comply with a legal obligation</li>
                <li>Protect and defend the rights or property of the Company</li>
                <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                <li>Protect the personal safety of Users of the Service or the public</li>
                <li>Protect against legal liability</li>
            </ul>

            <h3>Security of Your Personal Data</h3>
            <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>

            <h3>Opt-Out Policy</h3>
            <p>We value your privacy and understand that you may want to limit the information we collect and how we use it. Below are the methods you can use to opt out of certain data collection and marketing communications:</p>

            <h3>1. Marketing Communications</h3>
            <p>If you prefer not to receive promotional emails and advertising from us, you can opt out by:</p>
            <ul>
                <li><b>Clicking the Unsubscribe Link:</b> Each promotional email includes an unsubscribe link at the bottom. Click this link to remove yourself from our mailing list.</li>
                <li><b>Contacting Us Directly:</b> You can also email us at main@adanisbuildersgaragedoor.com with "Unsubscribe" in the subject line, and we will ensure that your request is processed.</li>
                <li>
                    <b>Booking Appointments and SMS Confirmation:</b>
                    When you book an appointment online through our website, you agree to receive SMS messages related to your appointment. These messages may include confirmations, reminders, or updates regarding your scheduled service. By scheduling an appointment online, you consent to receiving such SMS communications. Standard message and data rates may apply.
                    <br />
                    <br />
                    If you wish to opt-out of SMS communications, please contact us directly or reply to the SMS with the instructions provided to stop receiving further messages.
                </li>
            </ul>

            <h3>2. Advertising and behavioral targeting</h3>
            <p>We understand the importance of your privacy and your preferences regarding personalized advertising. If you wish to opt out of behavioral targeting, please follow the steps below:</p>
            <ul>
                <li><b>Network Advertising Initiative (NAI):</b> You can visit the NAI's opt-out page at www.networkadvertising.org/choices to manage your preferences for interest-based advertising.</li>
                <li><b>Digital Advertising Alliance (DAA):</b> The DAA provides an opt-out tool accessible at www.aboutads.info. This allows you to opt out of targeted advertising from participating companies.</li>
                <li><b>Your Browser Settings:</b> Most web browsers allow you to adjust your settings to refuse cookies or notify you when cookies are being sent. This can limit the ability of advertisers to track your behavior across different websites.</li>
            </ul>


            <h3>Children's Privacy</h3>
            <p>
                Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.
                <br />
                <br />
                If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.
            </p>

            <h3>Links to Other Websites</h3>
            <p>
                Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.
                <br />
                <br />
                We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
            </p>

            <h3>Changes to this Privacy Policy</h3>
            <p>
                We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
                <br />
                <br />
                We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
                <br />
                <br />
                You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
            </p>


            <h3>Contact Us</h3>
            <p>For questions, visit our website: <a href="https://www.adanisbuildersgaragedoor.com">https://www.adanisbuildersgaragedoor.com</a></p>
        </div>
    )
}


